import React from "react"

// Import Images
import Mail from '../images/icons/mail-icon.svg'
import Phone from '../images/icons/phone-icon.svg'

// Import Components
import Layout from "../components/layout"
import SEO from "../components/seo"

// Import Reveal Effects
import Fade from 'react-reveal/Fade';

const LocationSupport = () => {
  
  return (
    <Layout>
      <SEO title="Location support - Rooam" />
      <div className="section more-top no-bottom">
        <div className="container medium text-center">
          <h1 className="text-white no-top">Need some help?</h1>
          <p className="lead text-light-grey">Having technical problems? Contact us below, and a Rooam team member will be in touch with you as soon as we can.</p>
        </div>
      </div>
      <div className="section less-top">
        <div className="container medium columns">
          <div className="row clearfix">
            <Fade bottom distance={'1em'} duration={500}>
              <div className="column break-full">
                <div className="padding">
                  <a href="mailto:support@rooam.co" target="_blank" rel="noopener noreferrer">
                    <div className="box link background-dark-grey no-top-bottom">
                      <div className="box-padding text-center">
                        <div className="link-figure text-center">
                          <img src={Mail} alt="Mail Icon" />
                        </div>
                        <p className="font-weight-500 text-white no-top-bottom">support@rooam.co</p>
                        <div className="link-arrow text-center no-bottom">
                          <span className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 14 14"><path d="M3.8,0v1.5h4.9L0,10.2l1.1,1.1l8.7-8.7v4.9h1.5V0H3.8z"/></svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </Fade>
            <Fade bottom distance={'1em'} duration={500} delay={200}>
              <div className="column break-full">
                <div className="padding">
                  <a href="tel:1-202-719-5314" target="_blank" rel="noopener noreferrer">
                    <div className="box link background-dark-grey no-top-bottom">
                      <div className="box-padding text-center">
                        <div className="link-figure text-center">
                          <img src={Phone} alt="Phone Icon" />
                        </div>
                        <p className="font-weight-500 text-white no-top-bottom">+1 (202) 719-5314</p>
                        <div className="link-arrow text-center no-bottom">
                          <span className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 14 14"><path d="M3.8,0v1.5h4.9L0,10.2l1.1,1.1l8.7-8.7v4.9h1.5V0H3.8z"/></svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default LocationSupport
